let awsRegion = 'ap-southeast-2';

export default {
 api_urls: {
   reporting: 'https://jea8sa5xhd.execute-api.ap-southeast-2.amazonaws.com/api',
   geojson: 'https://q6krx17ir4.execute-api.ap-southeast-2.amazonaws.com/api',
   overlays: 'https://lbiv31jtt4.execute-api.ap-southeast-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 2277,
 crm_base_url: 'https://ccm.newcrest.forwoodsafety.com',
 forwood_id_url: 'https://id.newcrest.forwoodsafety.com?redirect_uri=https://geoeditor.newcrest.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.newcrest.forwoodsafety.com',
 Auth: {
   userPoolId: 'ap-southeast-2_2hHiDIs9H',
   userPoolWebClientId: '7lcjvbsnl401d4js1oh7dmfnbp',
   cookieStorage: {
     domain: '.newcrest.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
